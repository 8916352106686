.headerContainer {
  text-align: left;
  margin-bottom: 29px;

  &.isPartnerOrPairingFlow {
    display: block;
    padding-top: 15px;

    @media (--min-width-medium) {
      padding-top: 20px;
    }
  }

  .header {
    height: 30px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
    font-weight: var(--font-weight-bold);
    color: var(--ink-dark);

    @media (--min-width-small) {
      height: 24px;
      font-size: var(--font-size-6);
    }

    &.isPartnerFlow,
    &.isPairingFlow {
      color: var(--off-white);
      display: inline;
      font-size: var(--font-size-6);
    }

    &:not(.isPartnerFlow) {
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      text-align: center;
      height: auto;

      &:not(.isPairingFlow) {
        color: var(--secondary-color-5);
      }

      &.signInOrSignUp {
        @media (--min-width-small) {
          font-size: 54px;
        }
      }
    }
  }

  .subheader {
    font-size: 15px;
    line-height: 1.14;
    margin-top: 15px;
    color: var(--ink-dark);

    &.isPartnerFlow,
    &.isPairingFlow {
      color: var(--off-white);
    }

    &:not(.isPartnerFlow) {
      margin-top: 17px;
      font-weight: 400;
      font-size: var(--font-size-2);
      line-height: 19px;

      &:not(.isPairingFlow) {
        color: var(--secondary-color-5);
        text-align: center;
      }
    }

    span {
      margin-right: 4px;
    }
  }
}
