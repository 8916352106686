@use './form.module';

.span {
  composes: span from './checked-input.module.scss';
  border-radius: 24px;
  background-color: var(--modal-input-color);

  & .icon {
    margin: 4px;
  }
}

.radio {
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  padding-right: 1px;
  margin-bottom: 18px;
  line-height: 15px;

  &.isPairingFlow {
    box-shadow: unset;
    background-color: var(--ink-11);
    border-radius: 4px;
    color: var(--grey-6);
    border: 1px solid var(--grey-6);
    height: 100%;
    padding: 2% 0;
  }

  &.inline {
    width: 48%;
  }

  @media (--min-width-breakpoint-360) {
    padding-right: 2px;
  }

  @media (--min-width-small) {
    padding-left: 16px;
  }

  &.small {
    box-shadow: none;
    background-color: unset;
    height: auto;
    margin-bottom: 12px;
    padding: 0;

    &:last-child {
      margin-bottom: 4px;
    }
  }
}

.icon {
  composes: icon from './checked-input.module.scss';
  width: 7px;
  height: 7px;
  border-radius: 14px;
  background-color: var(--secondary-color-5);

  &.inkIcon {
    background-color: var(--ink-11);
  }

  &.coralIcon {
    background-color: var(--tunein-coral);
  }
}
