.cardIcon {
  margin-right: 6px;
}

.addressElement {
  padding-top: 0.75rem;
}

.container {
  text-align: left;
  padding-bottom: 0;
  min-height: inherit;
  width: 100%;
  height: 100vh;

  &.pairingFlow {
    height: unset;
  }
}

.containerRestrictions {
  max-width: var(--form-max-width-px);
  margin: 0 auto;

  &.withTopMargin {
    margin-top: 80px;

    @media (--min-width-large) {
      margin-top: 40px;
    }
  }
}

.topSection {
  padding: 15px var(--compact-container-padding) 10px;
  background-color: white;

  @media (--min-width-medium) {
    padding-top: 30px;
  }
}

.finePrintStyle {
  margin-bottom: 15px;
}

/* // TODO: reuse */
.title {
  margin: 0 auto 17px;
  color: var(--form-title-font-color);
  font-size: 27px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  text-align: left;

  @media (--min-width-xxsmall) {
    font-size: 36px;
    line-height: 1.2;
  }

  @media (--min-width-medium) {
    margin-top: 30px;
    font-size: var(--form-title-font-size-lg);
  }
}

.subtitle {
  composes: title;
  margin: 0 auto 25px;
  font-size: var(--font-size-2);
  line-height: 1.3;
  
  @media (--min-width-xxsmall) {
    font-size: var(--font-size-3);
  }

  @media (--min-width-medium) {
    margin-top: 0;
    font-size: var(--font-size-4);
  }

  span {
    font-weight: bold;
  }
}

.column {
  margin-bottom: 16px; /* for future floating label: 25px */
}

.button {
  margin: 13px 0 19px;
  height: 48px;

  &.coralStyling {
    background-color: var(--tunein-coral);
    color: var(--secondary-color-5);

    &:hover,
    &:focus,
    &[disabled] {
      background-color: var(--tunein-coral-hover) !important;

      span {
        color: var(--secondary-color-5);
      }
    }
  }
}

.leftProduct {
  float: left;
}

.rightProduct {
  float: right;
}

.productTitle {
  font-weight: var(--font-weight-bold);
  display: inline-block;
  font-size: var(--font-size-1);

  @media (--min-width-breakpoint-360) {
    font-size: 15px;
  }

  @media (--min-width-small) {
    font-size: var(--font-size-3);
  }

  @media (--min-width-xlarge) {
    padding-right: 8px;
  }

  &.noPadding {
    padding-right: 0;
  }
}

.productTitleDiscounted {
  color: var(--tunein-coral);
  font-weight: var(--font-weight-bold);
  font-weight: bold;
  display: inline-block;
  padding-right: 8px;

  &.noPadding {
    padding-right: 0;
  }
}

.productSubtitle {
  display: inline-block;
  font-size: var(--font-size-2);
}

.hiddenProductSubtitle {
  display: none;
}

.productDescriptionBreak {
  @media (--min-width-xlarge) {
    display: none;
  }
}

.productDescription {
  font-size: var(--font-size-3);
  line-height: 1.25;
  margin-bottom: 16px;
}

.copy {
  color: var(--secondary-color-5);
  font-size: var(--font-size-2);

  &.isPairingFlow {
    color: var(--off-white);
    text-align: left;
  }
}

.paymentOptionsFooterContainer {
  &.isPairingFlow {
    padding-bottom: 100px;
  }
}

.description {
  line-height: 1.3;
  color: var(--dark-sub-text);
  margin-bottom: 30px;
  text-align: center;
}

.bottomErrors {
  margin: 20px auto 0;
}

.footer {
  text-align: center;
  color: var(--secondary-color-5);
  font-size: var(--font-size-2);

  &.isPartnerOrPairingFlow {
    text-align: left;
    color: var(--grey-6);
  }
}

.error {
  color: var(--error-color);
  list-style: none;
  font-weight: normal;
  font-size: var(--font-size-2);
  line-height: 16px;
}

.link {
  composes: greenLink from '../../styles/common.module.scss';
  color: var(--secondary-color-5);
  text-decoration: underline;

  &.isPairingFlow {
    color: var(--off-white);

    i {
      background-color: var(--off-white) !important;
    }
  }
}

.legalLink {
  color: var(--secondary-color-5);
    text-decoration: underline;

  &.isPartnerOrPairingFlow {
    color: var(--grey-6);
  }

  &:hover {
    color: var(--cloud-grey);
  }
}

.legalLinkCheckbox {
  padding: 0 5px;
}

.creditCardFormTitle {
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
  font-size: 17px;

  &.isPairingFlow {
    margin-bottom: 20px;
  }
}

.innerPaymentFields {
  margin-top: 0;

  &.isPairingFlow {
    margin-top: 8px;
  }
}

.inputField {
  height: 48px !important;
}

.paymentFieldSection {
  margin-bottom: 16px;

  &.isPairingFlow {
    padding-top: 8px;

    input {
      &:autofill::first-line,
      &:autofill,
      &:autofill:hover,
      &:autofill:focus,
      &:autofill:active {
        font-family: inherit;
        background-color: var(--ink-11);
        caret-color: var(--off-white);
        -webkit-text-fill-color: var(--off-white);
        box-shadow: 0 0 0 1rem var(--ink-11) inset !important;
        filter: none; /* filter is a fireFox specific style */
      }
    }

    /* This is a hack to prevent overlapping of labels with autofilled input values on MUI Fields in WebKit browsers. */
    input:autofill + label {
      transform: scale(0.75) translate(0, -28px) !important;
    }
  }
}

.inlineField {
  @media (--min-width-small) {
    display: inline-block;
    width: 50%;

    &.isPairingFlow {
      width: 100%;
    }
  }
}

.leftField {
  @media (--min-width-small) {
    float: left;
    width: 95%;
  }
}

.rightField {
  @media (--min-width-small) {
    float: right;
    width: 95%;
  }
}

.onAirTermsCheckboxContainer {
  width: 16px;
  height: 16px;
}

.onAirTermsCheckbox {
  width: 12px;
  height: 10px;
}

.onAirTermsLabel {
  font-size: var(--font-size-1);
}

.subscriptionUnavailableTitle {
  color: var(--form-title-font-color);
  font-size: 30px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  text-align: left;
  margin-bottom: 16px;

  &.isPairingFlow {
    color: white !important;
  }
}

.subscriptionUnavailableSubTitle {
  color: var(--form-title-font-color);
  font-size: var(--font-size-3);
  line-height: 1;
  text-align: left;

  &.isPairingFlow {
    color: white;
  }
}

.errorContainer {
  text-align: center;
}

.inlineFieldsContainer {
  @media (--min-width-small) {
    display: flex;

    &.isPairingFlow {
      display: block;
    }
  }
}

@keyframes blink {
  /**
     * At the start of the animation the element
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }

  /**
     * At 20% the element is 70% visible and
     * then fades out slowly
     */
  20% {
    opacity: 0.7;
  }

  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}

.altPaymentOptionsRow {
  margin: 12px 0 18px;
}

.altPaymentButtonWrapper {
  float: left;
  width: 100%;
  margin-right: 0;

  /*
    commented out styles for tiny buttons in design mocks.
    Decided to temporarily use a 100% width
    since there is only one button. May revert later.

    width: 86px;
    margin-right: 15px;
  */
  @media (--min-width-xsmall) {
    width: 29%;
    margin-right: 22px;
  }

  @media (--min-width-xxlarge) {
    width: 30%;
  }

  @media (--min-width-xxxlarge) {
    width: 31%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.altPaymentButton {
  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  height: 40px;
  width: 100%;

  /*
  padding: 12px 10px;
  @media (--min-width-xsmall) {
    padding: 10px;
  }
  */
}

.paypalButton {
  background-color: var(--paypal-gold);
  position: relative;
  float: left;

  .paypalSvgWrapper {
    width: 94px;
    margin: 0 auto;
    height: 20px;

    /*
      width: 86px;
      @media (--min-width-xsmall) {
        width: 94px;
      }
    */
  }

  .paypalLogo {
    float: left;
    width: 14px;

    /*
      width: 12px;
      @media (--min-width-xsmall) {
        width: 14px;
      }
    */
  }

  .paypalNameLogo {
    float: left;
    width: 67px;
    margin-left: 5px;

    /*
      width: 50px;
      margin-left: 3px;
      @media (--min-width-xsmall) {
        width: 67px;
        margin-left: 5px;
      }
    */
  }

  svg {
    float: left;
  }

  .inProgress {
    top: 0;
    left: 0;
    position: absolute;
    height: 40px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .inProgress span {
    background-color: var(--paypal-gold);
    float: left;
    height: 100%;
    width: 33%;

    /**
     * Use the blink animation, which is defined above
     */
    animation-name: blink;

    /**
     * The animation should take 1.4 seconds
     */
    animation-duration: 1.4s;

    /**
     * It will repeat itself forever
     */
    animation-iteration-count: infinite;

    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    animation-fill-mode: both;
  }

  .inProgress span:nth-child(2) {
    /**
       * Starts the animation of the third dot
       * with a delay of .2s, otherwise all dots
       * would animate at the same time
       */
    animation-delay: 0.2s;
  }

  .inProgress span:nth-child(3) {
    /**
       * Starts the animation of the third dot
       * with a delay of .4s, otherwise all dots
       * would animate at the same time
       */
    animation-delay: 0.4s;
  }
}
