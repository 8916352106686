.span {
  composes: span from './checked-input.module.scss';
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.checkmark {
  background: transparent url('/assets/img/shared/checkmark-v2.svg') no-repeat;
  background-size: contain;
  width: 14px;
  height: 12px;
}
