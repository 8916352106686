@use './form.module';

.container {
  position: relative;
  display: inline-flex;
}

/*
  When it comes to FF and IE, killing the browser's default styling of checkboxes and radio buttons
  doesn't really work; that's because of partial support for `appearance: none`.  Have a look at
  issues like:
  http://stackoverflow.com/q/30081748

  Mozilla even warns against usage of appearance: none to kill standard browser rendering of
  checkboxes/radio buttons:
  https://developer.mozilla.org/en-US/docs/Web/CSS/-moz-appearance

  As such, we need to replace the style by hiding the input altogether, and styling around it.
  A negative z-index or `opacity: 0` will hide the input without removing it from the DOM, allowing
  for keyboard accessibility.  It shouldn't negatively impact SEO ranking.
*/
.input {
  opacity: 0;
}

.span {
  composes: errorStateBorder from '../../../styles/common.module.scss';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: var(--dark-sub-text);
  background-color: var(--grey-poupon);
  border: 1px solid transparent;

  &:hover:enabled {
    border: 1px solid var(--border-color);
  }

  .focus & {
    border: 1px solid var(--border-color);
  }
}

.icon {
  z-index: 1;
  opacity: 0;
  transition: var(--base-transition);
}

.label {
  composes: errorStateLabel from '../../../styles/common.module.scss';
  display: inline-flex;
  width: 100%;
  align-items: center;
  color: var(--secondary-color-5);
  cursor: pointer;
  font-weight: 100;
  font-size: var(--font-size-3);
  line-height: 1;

  &.disabled {
    cursor: default;
  }

  &.focus {
    color: var(--secondary-color-7);
  }
}
