.paymentFlowProgressBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 33px;

  @media (--min-width-medium) {
    margin-top: 0;
  }
}

.paymentFlowProgressBarStep {
  font-size: var(--font-size-1);
  font-weight: 700;
  line-height: 16px;
  color: var(--51st-shade-of-grey);
  border-bottom: 1px solid var(--51st-shade-of-grey);
  padding-bottom: 8px;
  flex-grow: 1;
  text-align: left;

  &:not(:last-child) {
    margin-right: 15px;

    @media (--min-width-medium) {
      margin-right: 11px;
    }
  }

  &.active {
    color: var(--secondary-color-5);
    border-bottom: 1px solid var(--secondary-color-5);
  }
}
