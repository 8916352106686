.infoText {
  color: var(--dark-sub-text);
  font-size: var(--font-size-3);
  line-height: normal;
}

.errorText {
  composes: infoText;
  color: var(--error-color);
}

.successText {
  composes: infoText;
  color: var(--primary-color-1);
}
