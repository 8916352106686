.formLayoutContainer {
  display: flex;
  justify-content: center;
  padding: 0 24px;

  &.isPairingFlow {
    padding: 0 24px;

    @media (--min-width-medium) {
      padding: 0 100px;
      width: 100%;
      max-width: 1000px;
    }
  }
}

.formLayoutInnerContainer {
  width: 100%;
  max-width: 1100px;
}

.innerFormLayoutContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (--min-width-medium) {
    flex-direction: row;
  }
}

.innerFormLayoutContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--min-width-medium) {
    flex-direction: row;
    align-items: start;
  }
}

.formContainer {
  width: 100%;
  max-width: var(--auth-form-max-width);
  background-color: white;
  border-radius: 2px;
  box-sizing: content-box;

  &.isPairingFlow {
    background-color: var(--ink-11);
  }

  @media (--min-width-medium) {
    margin-bottom: 16px;
    padding: 24px;
  }

  &.isOnlyAuthFlow {
    background-color: transparent;
  }
}

.noPadding {
  padding: 0;
}
