.discountPriceText {
  font-family: var(--primary-font-header-bold);
  color: var(--white);
  font-size: var(--font-size-3);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 1%;

  @media (--min-width-medium) {
    font-size: var(--font-size-2);
  }

  @media (--min-width-xsmall) {
    margin-bottom: 4%;
  }

  .strikeThroughPrice {
    font-family: inherit;
    text-decoration: line-through;
    font-size: 15px;
  }

  .upsellDiscountPrice {
    font-family: inherit;
    font-size: var(--font-size-6);
    line-height: 1;
    color: var(--tunein-coral);
  }

  .upsellTrialLength {
    font-size: var(--font-size-3);

    @media (--min-width-medium) {
      font-size: var(--font-size-2);
    }
  }
}

.upsellTitle {
  color: var(--white);

  @media (--min-width-small) {
    display: block;
  }
}

.upsellSubtitle {
  color: var(--tunein-coral);
  display: none;

  @media (--min-width-small) {
    display: block;
  }
}

.profileDetails {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.4rem;

  img {
    width: 56px;
    max-width: 100%;
    border-radius: 2px;

    @media (--min-width-breakpoint-360) {
      width: 72px;
    }
  }

  span {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-3);
    color: var(--white);
    line-height: 1.2;
    margin-left: 0.75rem;
  }
}

.upsellFormContainer {
  min-width: 253px;
  border-radius: 16px;
  background-color: var(--secondary-color-5);
  text-align: left;
  margin-bottom: 16px;
  padding: 8px 24px;
  background-image: url('/assets/img/premium/upsell/upsell_bkg_pattern_transparent.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;

  @media (--min-width-xsmall) {
    padding: 16px 24px;
  }

  @media (--min-width-small) {
    margin-top: 15px;
  }

  @media (--min-width-medium) {
    margin-right: 2%;
  }

  h1,
  span {
    font-family: var(--primary-font-header-bold);
    font-size: var(--font-size-4);
    line-height: 0.83;
    font-weight: bold;

    @media (--min-width-breakpoint-360) {
      font-size: 22px;
    }

    @media (--min-width-xsmall-1) {
      font-size: var(--font-size-6);
    }

    @media (--min-width-small) {
      font-size: var(--font-size-7);
      line-height: 1;
    }
  }

  .tableText {
    text-align: left;
    font-weight: normal;
    font-size: var(--font-size-2);
    padding-left: 14px;

    @media (--min-width-breakpoint-360) {
      font-size: var(--font-size-3);
    }

    @media (--min-width-xsmall) {
      font-size: var(--font-size-2);
    }
  }

  .footnoteText {
    text-align: left;
    font-weight: normal;
    font-size: var(--font-size-1);
    color: var(--white);
  }

  table {
    border-collapse: separate;
    border-spacing: 0 12px;
    color: var(--white);
    line-height: 1.29;
  }
}

.premiumLogo {
  color: var(--white);
  font-family: var(--primary-font-header-bold);
  background-color: var(--tunein-coral);
  border-radius: 2px;
  font-size: var(--font-size-1);
  display: inline-block;
  padding: 6px 8px;
  margin-bottom: 11px;
  margin-top: 14px;
  line-height: 0.8;

  @media (--min-width-breakpoint-360) {
    font-size: 15px;
    padding: 8px 10px;
  }
}

.customSourceLogo {
  width: 250px;
  display: block;
  margin: auto;

  @media (--min-width-medium) {
    margin: 0;
  }

  @media (--min-width-breakpoint-360) {
    margin: auto;
  }
}

.topMargin {
  margin-top: 10px;
}

.onAirImage {
  background-image: url('/assets/img/premium/upsell/on-air.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 175px;
  width: 250px;
  margin: auto;
}
